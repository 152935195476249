
import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'
import '../../../styles/index.scss'

const IndexPage = () => {
    return (
        <Layout lang="es">

            <SEO
                title="Optiwe - Sistema de CRM para WhatsApp y Concesionarios "
                description="Conecta a todos los equipos de tu concesionario al CRM #1 para WhatsApp y mejora la comunicación entre los clientes y tu empresa."
                lang="es"
                locales={
                    [
                        { hreflang: 'es', href: 'https://optiwe.com/es/soluciones/concesionarios/' }
                    ]
                }
            />

            <main className="container-fluid ow-banner">
                {
                    /*
                    ^*********
                    * HEADER *
                    **********
                    */
                }
                <Nav lang="es" showButtons />

                {
                    /*
                    ***************
                    * MAIN BANNER *
                    ***************
                    * TODO: If the banner is used in another view, create a <Banner /> component
                    */
                }
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                            <h1 className="ow-section--cards__title">
                                <span className="bold">Gestión de clientes por WhatsApp para <strong>CONCESIONARIOS</strong></span>
                            </h1>
                            <p className="ow-section--cards__desc">
                                Los concesionarios brindan diferentes servicios, desde la compra/venta de autos nuevos y usados hasta el soporte técnico y mantenimiento de los mismos. Debido a la cantidad de servicios ofrecidos, es fundamental contar con una herramienta que centralice toda la comunicación con los clientes por WhatsApp y que también conecte los procesos de los diferentes departamentos de la empresa para ofrecer una experiencia unificada y coherente al consumidor.
                            </p>
                            <p className="ow-section--cards__desc">
                                Permite a tus clientes comunicarse con tu empresa a través de sus canales de uso habitual como Whatsapp, Facebook e Instagram y ten disponible una atención 24/7 para despejar todas sus dudas frecuentes relacionadas a cualquiera de tus servicios, sea venta, postventa, servicio técnico, administración u otros.
                            </p>
                            <p className="ow-section--cards__desc">
                                Ten a todo tu equipo conectado en una única plataforma multiagente y automatiza su organización, para que al momento de solicitar el contacto con un asesor, tus clientes sean derivados a las personas adecuadas.
                            </p>
                            <p className="ow-section--cards__desc">
                                Puedes utilizar chatbots simples que pregunten al cliente con qué departamento quieren operar (por ejemplo ventas, mantenimiento o administración) y luego hagan la derivación al grupo de agentes de atención correcto. Rápidamente notarás el cambio positivo en la generación de nuevos leads, fidelización de tus clientes habituales, procesos de ventas y la calidad y rendimiento de tu servicios en general.
                            </p>
                            <p className="ow-section--cards__desc">
                                Personaliza el flujo de comunicación con cada una de las áreas de tu empresa, ofreciendo respuestas a preguntas frecuentes (por ejemplo planes de financiación, horarios de atención, dirección de las oficinas, etc.) y guiando al cliente para obtener toda la información necesaria antes de llegar a un agente.
                            </p>
                            <p className="ow-section--cards__desc">
                                Libera el tiempo que tus agentes de atención usan en responder preguntas frecuentes y permite que dediquen más tiempo a brindar atención de forma más personalizada. Esto te permite reducir tus costos operativos y a la vez mejorar la experiencia de compra del cliente.
                            </p>
                            <p className="ow-section--cards__desc">
                                Facilita el proceso de agendar citas de venta, prueba o de servicio técnico y mantén una comunicación fluida, enviando notificaciones y recordatorios sobre las mismas.
                            </p>
                            <p className="ow-section--cards__desc">
                                Configura el bot para, a través de preguntas personalizadas, lograr una mejor calificación de los clientes potenciales y realiza campañas masivas de marketing por WhatsApp con ofertas, promociones y novedades.
                            </p>
                            <p className="ow-section--cards__desc">
                                Brinda a tus equipos de atención al cliente las herramientas de gestión interna, como etiquetas de conversaciones, notas, respuestas preconfiguradas para optimizar el flujo de trabajo.
                            </p>
                        </div>
                    </div>
                </div>
            </main>

        </Layout>
    )
}

export default IndexPage
